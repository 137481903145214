import Avatar from 'components/UI/Avatar'
import UserPreview from 'components/UI/UserPreview'
import { navigate } from 'gatsby'
import { IconButton, Writing } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'

interface IProps {
  kids: any[]
  onKidSelected: (kid: any) => void
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 40px;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media only screen and (max-width: 625px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

const KidContainer = styled.div`
  position: relative;
`

const Images = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  > :not(:first-child) {
    margin-left: -35%;
  }
  margin-bottom: 10px;
`

const WritingsContainer = styled.div`
  padding: 25px;
  border-radius: 20px;
  background-color: #341644;
  box-shadow: inset 0 5px 2px -2px #531d75, 0 10px 2px -2px #0000004d;
  position: relative;
  width: 100%;
`

const ButtonContainter = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const CardLayout = (props: IProps) => {
  return (
    <Wrapper>
      {(props.kids || []).length > 1 ? (
        <KidContainer>
          <WritingsContainer>
            <Images>
              {(props.kids || []).slice(0, 5).map((kid) => (
                <Avatar key={kid._id} user={kid} size="75px" />
              ))}
            </Images>
            <ButtonContainter
              onClick={() => {
                navigate('/writings/')
              }}
            >
              <IconButton
                icon={<Writing />}
                theme="purple"
                size="x-small"
              >
                All Children's Writing
              </IconButton>
            </ButtonContainter>
          </WritingsContainer>
        </KidContainer>
      ) : null}
      {(props.kids || []).map((kid) => (
        <KidContainer key={kid._id}>
          <UserPreview {...kid} onClick={() => props.onKidSelected(kid)} />
        </KidContainer>
      ))}
    </Wrapper>
  )
}

export default CardLayout
