import { useApolloClient } from '@apollo/client'
import { useState } from 'react'
import { AppState } from '../../state/AppState'
import GET_OWNERSHIPS from './getOwnerships.graphql'

export default () => {
  const { ownerships, setOwnerships } = AppState.useContainer()
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()

  const fetch = async () => {
    if (ownerships.length > 0) return
    setLoading(true)
    const { data } = await client.query({
      query: GET_OWNERSHIPS,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })
    setOwnerships((data.me.accountOwnerships || []).filter(o => o.status === 'ACCEPTED').map(o => {
      let isExpired = false
      if (o.zookeeper.parentAccount && o.zookeeper.parentAccount.subscription && o.zookeeper.parentAccount.subscription.status === 'cancelled') {
        isExpired = true
      }
      if (new Date(o.zookeeper.subscriptionEndDate).getTime() < Date.now()) {
        isExpired = true
      }
      if (o.zookeeper.parentAccount && o.zookeeper.parentAccount.subscription.current_period_end && o.zookeeper.parentAccount.subscription && o.zookeeper.parentAccount.subscription.current_period_end >= 0 && o.zookeeper.parentAccount.subscription.current_period_end * 1000 < Date.now()) {
        isExpired = true
      }
      if (o.zookeeper.parentAccount && o.zookeeper.parentAccount.subscriptionEndDate && new Date(o.zookeeper.parentAccount.subscriptionEndDate).getTime() < Date.now()) {
        isExpired = true
      }
      return { ...o, isExpired }
    }))
    setLoading(false)
  }

  return {
    fetch,
    loading,
    zookeepers: (ownerships || []).map((o) => ({ ...o.zookeeper, isExpired: o.isExpired })),
  }
}
