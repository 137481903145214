import { navigate } from 'gatsby'
import { Button, Close } from 'nzk-react-components'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { AppState } from '../../state/AppState'
import { CurrentUserState } from '../../state/CurrentUserState'
import FlatTick from "../UI/Icons/FlatTick"

const Wrapper = styled.div`
  z-index: 21;
  background-color: #341644 !important;
  border-radius: 20px;
  width: 280px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const Content = styled.div`
  z-index: 2;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background-color: #341644;
  width: 280px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
`

const Title = styled.div`
  font-size: 22px;
  line-height: 26px;
  text-shadow: 0px 3px #afafaf, 0px 6px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-family: 'Rammetto One', cursive;
  margin-bottom: 0px;
  text-align: center;
`

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  font-family: 'Libre Baskerville';
  color: #FFFFFF;
  margin-bottom: 10px;
  p {
    text-align: center;
    
  }
`

const SectionItem = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  text-align: left;
  > :first-child {
      position: absolute;
      top: 0px;
      left: 0px;
  }
  > :last-child {
    padding-left: 40px;
  }

`

const Delete = styled.div`
  position: absolute;
  right: -11px;
  top: -11px;
  z-index: 2;
`
interface RegistrationPopUpProps {
  closeWindow: (bool: boolean) => void
}

const ExtendTrialPopup = (props: RegistrationPopUpProps) => {
  const { currentUser } = CurrentUserState.useContainer()
  const { ownerships } = AppState.useContainer()
  const closeWindow = () => {
    props.closeWindow(false)
  }

  useEffect(() => {
    if (typeof amplitude !== 'undefined') {
      amplitude.getInstance().logEvent('Dashboard: View extend trial home page popup')
    }
  }, [])

  if (currentUser?.customPlan || ownerships >= 0) {
    navigate('/welcome?page=SelectAPlan')
    return null
  }

  return <Wrapper>
    <Content>
      <Title>Get 7 More<br />days Free!</Title>
      <Section>
        <p>Upgrade your trial to get 7 more Days free, plus:</p>
        <SectionItem><FlatTick color="#8CC63F" size={30} /><div>Feedback on your child's work from expert educators</div></SectionItem>
        <SectionItem><FlatTick color="#8CC63F" size={30} /><div>Unlock all premium features</div></SectionItem>
        <SectionItem><FlatTick color="#8CC63F" size={30} /><div>Your child can take part in global writing competitions</div></SectionItem>
      </Section>
      <div>
        <Button theme='primary' size='x-small' onClick={() => {navigate('/welcome?page=SelectAPlan')}}>
          Upgrade my trial
        </Button>
      </div>
    </Content>
    <Delete>
      <Button theme="red" size="x-small" round onClick={closeWindow}>
        <Close />
      </Button>
    </Delete>
  </Wrapper>
}

export default ExtendTrialPopup
