import React from 'react'
import Layout from '../layouts/layout'
import HomePage from '../components/pages/HomePage'

const HomePageWrapper = () => {
  if (typeof window === "undefined") return null

  return <Layout>
    <HomePage />
  </Layout>
}

export default HomePageWrapper
