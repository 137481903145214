import { Button, Close } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`

const Wrapper = styled.div`
  position: relative;
  background-color: #2F163B;
  box-shadow: 0 -4px 0 #4B1B68, 0 4px 0 #1B0525, 0 8px 0 rgba(0,0,0,0.4);
  border-radius: 13px;
  color: #fff;
  padding: 20px 20px;
  font-family: "Libre Baskerville";
  text-align: center;
`

const Title = styled.div`
  font-family: "Rammetto One";
  margin-bottom: 20px;
`

const Content = styled.div``

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, -20%);
`

interface IProps {
  dismiss: () => void,
  ownership: any
}

const FollowSuccess = (props: IProps) => {
  return <Overlay onClick={props.dismiss}>
    <Wrapper onClick={e => {
      e.preventDefault()
      e.stopPropagation()
    }}>
      <CloseButton onClick={props.dismiss}>
        <Button theme="red" size="small" round>
          <Close />
        </Button>
      </CloseButton>
      <Title>Success</Title>
      <Content>
        You've successfully activated the follower account!
      </Content>
    </Wrapper>
  </Overlay>
}

export default FollowSuccess
