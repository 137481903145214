import { useLocation } from '@reach/router'
import ShopAdBanner from 'components/ShopAdBanner'
// import DashboardOnboarding from 'components/Modals/DashboardOnboarding'
import { navigate } from 'gatsby'
import { IconButton, User } from 'nzk-react-components'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRetentionOfferModalAutoClaim } from 'modules/retention/components/AutoClaimRetentionOfferModal'
import useChildLoginPopup from 'hooks/useChildLoginPopup'
// @ts-ignore
import ExtendYourTrial from '../../../assets/images/extend-your-trial.jpg'
import { CurrentUserState } from '../../../state/CurrentUserState'
import { ModalState } from '../../../state/ModalState'
import ExtendTrialPopUp from '../../ExtendTrialPopup'
import HelpfulLinks, { ILink } from '../../HelpfulLinks'
import KidsSelector from '../../KidsSelector'
import Ownerships from '../../Ownerships'
import RegistrationPopUp from '../../RegistrationPopUp'
import WelcomePopup from '../../WelcomePopup'
import FollowSuccessModal from './FollowSuccessModal'


const PageContainer = styled.div`
  position: relative;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 20px;
  font-family: 'Rammetto One';
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 1024px;
  padding-bottom: 20px;
  @media screen and (max-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`
const WelcomeBackContainer = styled.div`
  position: relative;
  display: block;
  align-self: center;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  width: 100%;
  height: auto;
  margin-top: 10px;
  @media screen and (max-width: 800px) {
    > :first-child {
      width: 100%;
      text-align: center;
    }
    > :nth-child(2) {
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 800px) {
    > :first-child {
      width: 100%;
      text-align: center;
    }
    > :last-child {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
`

const Section = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`

export const SectionTitle = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
  color: #ffffff;
  align-items: left;
  justify-content: left;
  align-self: flex-start;
`

const WelcomeText = styled.div`
  font-size: 30px;
  align-items: center;
  color: #ffffff;
  text-shadow: 0px 5px #afafaf, 0px 10px #120219;
  white-space: nowrap;
  margin-bottom: 20px;
`

const BlackPanel = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 20;
  overflow: none;
`

const IndexPage = () => {
  const [welcomePopup, setWelcomePopup] = useState(false)
  const [registrationPopUp, setRegistrationPopUp] = useState(false)
  const [extendTrialPopup, setExtendTrialPopup] = useState(false)
  const { displayModal, dismissModal } = ModalState.useContainer()
  const location = useLocation()
  const { currentUser, signUp } = CurrentUserState.useContainer()
  const { openChildLoginPopup } = useChildLoginPopup()
  
  useRetentionOfferModalAutoClaim({ on: 'login' })

  const [conditionalHelpfulLinks, setConditionalHelpfulLinks] = useState<
    ILink[]
  >([])
  let extendTrialPopupTimeout

  const startTimeoutForExtendTrialPopup = (delay = 1000 * 60 * 1) => {
    if (
      currentUser &&
      currentUser.subscription &&
      currentUser.subscription.type === 'kid-first' &&
      !currentUser.subscription.status
    ) {
      extendTrialPopupTimeout = setTimeout(() => {
        setExtendTrialPopup(true)
      }, delay)
    }
  }


  useEffect(() => {
    if (typeof amplitude !== 'undefined') {
      amplitude.getInstance().logEvent('Dashboard: View home page')
    }
    const queryString = window.location.search

    const urlParams = new URLSearchParams(queryString)

    const pageType = urlParams.get('registration')

    if (pageType === 'complete' && !signUp) {
      // @ts-ignore
      // eslint-disable-next-line
      history.replaceState(null, null, window.location.pathname)
      localStorage.removeItem('should-show-welcome-video')
      setRegistrationPopUp(true)
    } else if (localStorage.getItem('should-show-welcome-video')) {
      localStorage.removeItem('should-show-welcome-video')
      setWelcomePopup(true)
    } else {
      startTimeoutForExtendTrialPopup(1000 * 20) // 20 seconds
    }

    // @ts-ignore
    if (
      currentUser &&
      currentUser.subscription &&
      currentUser.subscription.type === 'kid-first' &&
      !currentUser.subscription.status
    ) {
      setConditionalHelpfulLinks([
        // @ts-ignore
        ...conditionalHelpfulLinks.filter(t => t.title === 'Get 7 More days Free!'),
        {
          title: 'Get 7 More days Free!',
          // @ts-ignore
          onClick: () => {
            clearTimeout(extendTrialPopupTimeout)
            setExtendTrialPopup(true)
          },
          image: {
            url: ExtendYourTrial,
          },
        },
      ])
    }

    if (
      currentUser &&
      currentUser.subscription &&
      currentUser.children.findIndex(d => d.starTutoring?._id) === -1
    ) {
      setConditionalHelpfulLinks([
        // @ts-ignore
        ...conditionalHelpfulLinks.filter(t => t.title === 'Advanced Tutoring'),
        {
          shownToParents: true,
          shownToTeachers: false,
          title: 'Advanced Tutoring',
          // @ts-ignore
          onClick: () => {
            navigate('/advanced-tutoring')
          },
          image: {
            url: '/images/advanced-tutoring/working-tutor-logo.jpeg',
          },
        },
      ])
    }

    return () => {
      clearTimeout(extendTrialPopupTimeout)
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get('successFollow')) {
      displayModal(
        <FollowSuccessModal
          dismiss={() => {
            navigate('/')
            dismissModal()
          }}
          ownership={(currentUser?.accountOwnerships || []).filter(
            (d) => d._id === params.get('successFollow')
          )}
        />
      )
    }
  }, [])

  const shouldRenderExtendTrialPopup = () => {
    return (
      currentUser &&
      currentUser.subscription &&
      currentUser.subscription.type === 'kid-first' &&
      !currentUser.subscription.status
    )
  }

  const onWelcomePopupClose = () => {
    setWelcomePopup(false)
    if (shouldRenderExtendTrialPopup()) {
      startTimeoutForExtendTrialPopup(1000 * 60 * 1) // 1 min
    } else if (currentUser && !currentUser.tutorial?.manageFeatures) {
      // Other modal
      // displayModal(
      //   <DashboardOnboarding dismiss={dismissModal} />
      // )
    }
  }

  const onExtendTrialPopupClose = () => {
    setExtendTrialPopup(false)
    if (shouldRenderExtendTrialPopup()) {
      startTimeoutForExtendTrialPopup(1000 * 60 * 2) // 2 min
    } else if (currentUser && !currentUser.tutorial?.manageFeatures) {
      // Other modal
      // displayModal(
      //   <DashboardOnboarding dismiss={dismissModal} />
      // )
    }
  }

  const openChildLogin = () => {
    if (!currentUser?.children || currentUser.children.length === 0) return
    openChildLoginPopup(currentUser.children.map((u => {
      return {
        username: u.username,
        avatar: u.avatar,
        nickname: u.nickname
      }
    })))
  }

  return (
    <PageContainer>
      {(registrationPopUp || extendTrialPopup || welcomePopup) && (
        <BlackPanel />
      )}
      <Content>
        {registrationPopUp && (
          <RegistrationPopUp closeWindow={setRegistrationPopUp} />
        )}
        {extendTrialPopup && (
          <ExtendTrialPopUp closeWindow={onExtendTrialPopupClose} />
        )}
        {welcomePopup && <WelcomePopup onClose={onWelcomePopupClose} />}
        <WelcomeBackContainer>
          <WelcomeText>Your Dashboard</WelcomeText>
          <IconButton
            onClick={openChildLogin}
            icon={<User />}
            theme="primary"
            size="x-small"
          >
            Child Login
          </IconButton>
        </WelcomeBackContainer>
        <Section>
          <SectionTitle>Helpful links</SectionTitle>
          <HelpfulLinks prependLinks={conditionalHelpfulLinks} />
        </Section>
        <Section>
          <KidsSelector />
        </Section>
        <Section>
          <Ownerships />
        </Section>
      </Content>
      <ShopAdBanner />
    </PageContainer>
  )
}

export default IndexPage
