import { navigate } from 'gatsby'
import { Button, Close, IconButton } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  z-index: 21;
  background-color: #341644 !important;
  border-radius: 20px;
  width: 280px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const Content = styled.div`
  z-index: 2;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background-color: #341644;
  width: 280px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
`

const Title = styled.div`
  font-size: 22px;
  line-height: 26px;
  text-shadow: 0px 3px #afafaf, 0px 6px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-family: 'Rammetto One', cursive;
  margin-bottom: 10px;
  text-align: center;
`

const Section = styled.div`
  color: #FFFFFF;
  font-family: 'Libre Baskerville';
  font-size: 18px;
  margin-bottom: 5px;

  strong {
    font-weight: bold;
  }
`

const Delete = styled.div`
  position: absolute;
  right: -11px;
  top: -11px;
  z-index: 2;
`
interface RegistrationPopUpProps {
  closeWindow: Function
}

const RegistrationPopUp = (props: RegistrationPopUpProps) => {
  const closeWindow = () => {
    props.closeWindow(false)
  }

  return <Wrapper>
    <Content>
      <Title>Registration Complete</Title>
      <Section>
        <p>Confirmation and child login details have been emailed to you.</p>
        <p><strong>All premium features are now unlocked.</strong></p>
        <p>We recommend you manage these features to get the best setup for you.</p>
      </Section>
      <Button theme='primary' size='x-small' onClick={() => {navigate('/students?deepLink=/student/{{username}}?page=features')}}>
        Manage Features
      </Button>
    </Content>
    <Delete>
      <IconButton
        size='x-small'
        icon={<Close />}
        onClick={closeWindow}
        theme='red'/>
    </Delete>
  </Wrapper>
}

export default RegistrationPopUp
