import BaseModalContent from 'components/Modals/BaseModalContent'
import React from 'react'
import { Wrapper } from './Modal.styles'

interface IProps {
  dismiss: () => void
  offer: {
    id: string
    content: {
      title: string
      subTitle: string
      willUnlockMessage: string
      worth: string
      items: {
        imageUrl: string
        title: string
        subTitle: string
        description: string
      }[]
    }
  }
}

export const AutoClaimRetentionOfferModal = (props: IProps) => {
  return <BaseModalContent dismiss={props.dismiss}>
    <Wrapper>
      <h1 className='title'>Great News!</h1>
      <h2 className='sub-title'>{props.offer.content.subTitle}</h2>
      <div className='items'>
        {
          props.offer.content.items.map((item, i) => {
            return <div className='item' key={i}>
              <div className='item--image'>
                <img src={item.imageUrl} alt={item.title} />
              </div>
              <div className='item--title'>
                {item.title}
              </div>
              <div className='item--sub-title'>
                {item.subTitle}
              </div>
              <div className='item--description'>
                {item.description}
              </div>
            </div>
          })
        }
      </div>
      <div className='worth'>{props.offer.content.worth}</div>
    </Wrapper>
  </BaseModalContent>
}
