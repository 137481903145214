import VimeoEmbed from 'components/VimeoEmbed'
import { Button, Close, IconButton, Tick } from 'nzk-react-components'
import React, { useState } from 'react'
import { useCurrentUserState } from 'state/CurrentUserState'
import styled from 'styled-components'
import DashboardProgressImage from '../../assets/images/dashboard-progress.png'
import ProgressImage from '../../assets/images/progress.png'
import SamImage from '../../assets/images/sam.png'
import ZookeeperImage from '../../assets/images/zookeeper.png'
import WillImage from '../../assets/images/will.png'
import RiyaImage from '../../assets/images/riya.png'
import ChildButtonImage from '../../assets/images/child-button.png'

const Wrapper = styled.div`
  z-index: 21;
  background-color: #341644 !important;
  border-radius: 20px;
  width: 90%;
  max-width: 700px;
  position: fixed;
  left: 50%;
  top: 50%;
  box-shadow: 0 -5px 0 #531D75, 0 10px 0 #1C042B, 0 14px 0 rgba(0,0,0,0.3);
  transform: translateX(-50%) translateY(-50%);
`

const Content = styled.div`
  z-index: 2;
  width: 100%;
  padding: 30px 30px 50px 30px;
  border-radius: 20px;
  background-color: #341644;
  display: flex;
  color: white;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .title {
    font-family: 'Rammetto One';
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
    text-shadow: 0px 4px #9A9A9A, 0px 8px rgba(0,0,0,.5);
    @media screen and (max-width: 420px) {
      font-size: 24px;
      text-shadow: 0px 3px #9A9A9A, 0px 6px rgba(0,0,0,.5);
    }
  } 
  p {
    font-size: 18px;
    font-family: 'Libre Baskerville';
    text-align: center;
  }
`

const Actions = styled.div`
  position: absolute;
  bottom: -30px;
  display: flex;
  justify-content: space-evenly;
  gap: 40px;
`

const Delete = styled.div`
  position: absolute;
  right: -20px;
  top: -20px;
  z-index: 2;
`

const ProgressSlideIllustration = styled.div`
  position: relative;
  background-color: #2A063B;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  .zookeeper, .sam {
    position: absolute;
  }
  .zookeeper {
    bottom: 0px;
    left: -20px;
    height: 240px;
  }
  .sam {
    top: 30px;
    right: 0px;
    height: 150px;
  }
  .progress {
    height: 100%;
  }

  @media screen and (max-width: 640px) {
    .sam, .zookeeper {
      display: none;
    }
  }
`

const LoginSlideIllustration = styled.div`
  position: relative;
  background-color: #2A063B;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;

  .riya, .will {
    position: absolute;
  }
  .will{
    bottom: 0px;
    right: 0px;
    height: 240px;
  }
  .riya {
    bottom: 0px;
    left: 0px;
    height: 240px;
  }
  .child-login {
    width: 60%;
  }

  @media screen and (max-width: 640px) {
    .will, .riya {
      display: none;
    }
    height: 100px;
  }
`

interface WelcomePopupProps {
  onClose: () => void
}

const TEACHER_VIDEO = '678228681'
const PARENT_VIDEO = '864446074'

const WelcomePopup = (props: WelcomePopupProps) => {
  const { currentUser } = useCurrentUserState()
  const [slideIndex, setSlideIndex] = useState(0)

  const isTeacher = currentUser?.userType === 'teacher'

  const incSlideIndex = () => setSlideIndex(slideIndex + 1)
  const decSlideIndex = () => setSlideIndex(slideIndex - 1)

  return <Wrapper>
    <Content>
      { slideIndex === 0 && isTeacher && <>
        <VimeoEmbed vimeoId={TEACHER_VIDEO} />
        <Actions><Button theme='primary' onClick={props.onClose} size='regular'>Continue</Button></Actions>
      </> }
      { slideIndex === 0 && !isTeacher && <>
        <VimeoEmbed vimeoId={PARENT_VIDEO} />
        <Actions><Button theme='primary' onClick={incSlideIndex} size='regular'>Next</Button></Actions>
      </> }
      { slideIndex === 1 && !isTeacher && <>
        <div className='title'>Your Dashboard</div>
        <p>Your child is able to independently use Night Zookeeper. As they progress through the program, an overview of their completed work will be available in this parent dashboard.</p>
          <img src={DashboardProgressImage} alt='Dashboard Progress' />
        <p>It'll be empty right now, but get your child logged in and you’ll soon see all the progress they are making.</p>
        <Actions>
          <Button theme='primary' onClick={decSlideIndex} size='regular'>Back</Button>
          <Button theme='primary' onClick={incSlideIndex} size='regular'>Next</Button>
        </Actions>
      </> }
      { slideIndex === 2 && !isTeacher && <>
        <div className='title'>Progress</div>
        <p>Details of your child's achievements, such as the challenges they have completed, and the curriculum topics they are working through, can be found on the 'Progress' page.</p>
        <ProgressSlideIllustration>
          <img className='zookeeper' src={ZookeeperImage} alt='Zookeper' />
          <img className='progress' src={ProgressImage} alt='Progress' />
          <img className='sam' src={SamImage} alt='Sam' />
        </ProgressSlideIllustration>
        <Actions>
          <Button theme='primary' onClick={decSlideIndex} size='regular'>Back</Button>
          <Button theme='primary' onClick={incSlideIndex} size='regular'>Next</Button>
        </Actions>
      </> }
      { slideIndex === 3 && !isTeacher && <>
        <div className='title'>Log In Your Child</div>
        <p>To log in, simply click the 'Child Login' button on your parent dashboard, or log in through the Night Zookeeper homepage.</p>
        <LoginSlideIllustration>
          <img className='riya' src={RiyaImage} alt='riya' />
          <img className='child-login' src={ChildButtonImage} alt='child login button' />
          <img className='will' src={WillImage} alt='will' />
        </LoginSlideIllustration>
        <Actions>
          <Button theme='primary' onClick={decSlideIndex} size='regular'>Back</Button>
          <IconButton icon={<Tick />} theme='confirm' onClick={props.onClose} size='regular'>Done</IconButton>
        </Actions>
      </> }
    </Content>
    <Delete>
      <Button theme="red" size="regular" round onClick={props.onClose}>
        <Close />
      </Button>
    </Delete>
  </Wrapper>
}

export default WelcomePopup
