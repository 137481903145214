import Select from 'components/UI/Select'
import { Link } from 'gatsby'
import { Button } from 'nzk-react-components'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCurrentUserState } from 'state/CurrentUserState'
import styled from 'styled-components'
import { SectionTitle } from '../pages/HomePage'
import CardLayout from './CardLayout'
import TableLayout from './TableLayout'
import useKidsSelector from './useKidsSelector'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 20px;
  color: white;
`

const Search = styled.div`
  position: absolute;
  top: -50px;
  right: 0px;
  > input {
    width: 280px;
    height: 36px;
    border-radius: 20px;
    border: none;
    outline: none;
    ::placeholder {
      color: #b4b4b3;
    }
    > :active {
      border: none;
    }
    background-color: transparent;
    font-family: 'Libre Baskerville';
    font-size: 18px;
    color: #000000;
    background-color: #ffffff;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  @media only screen and (max-width: 625px) {
    position: relative;
    top: 0px;
    right: auto;
    > input {
      width: 100%;
    }
  }
`

const Content = styled.div`
  position: relative;
`

const ChangeLayout = styled.div`
  width: 200px;
  margin-bottom: 20px;
  margin-top: -12px;
  font-size: 12px;
  font-family: 'Libre Baskerville';
`

const LAYOUT = {
  CARD: 'CARD_LAYOUT',
  TABLE: 'TABLE_LAYOUT',
}

const KidsSelector = ({ onKidSelected }) => {
  const { kids, fetch, loading, hasInvitedFollowers } = useKidsSelector()
  const { currentUser } = useCurrentUserState()
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('nickname')
  const [sortDirection, setSortDirection] = useState(-1)
  const [layout, setLayout] = useState<string>(LAYOUT.CARD)

  useEffect(() => {
    fetch('cache-first')
    if (
      typeof window !== 'undefined' &&
      localStorage.getItem('preferred-layout')
    ) {
      setLayout(localStorage.getItem('preferred-layout') || LAYOUT.CARD)
    }
    // eslint-disable-next-line
  }, [])

  const displayedKids = useMemo(() => {
    if (!currentUser) return []
    return (kids || [])
      .filter((k) => !k.following)
      .map((k) => {
        let isExpired = false
        if (
          currentUser.subscription &&
          currentUser.subscription.status === 'cancelled'
        ) {
          isExpired = true
        }
        if (
          currentUser.subscription &&
          currentUser.subscription.current_period_end &&
          currentUser.subscription.current_period_end >= 0 &&
          currentUser.subscription.current_period_end * 1000 < Date.now()
        ) {
          isExpired = true
        }
        if (
          currentUser.subscriptionEndDate &&
          new Date(currentUser.subscriptionEndDate).getTime() < Date.now()
        ) {
          isExpired = true
        }
        return {
          ...k,
          isExpired,
        }
      })
  }, [kids, currentUser])

  const fieldsForSortBy = (a, b, field: string) => {
    if (field === 'wordCount') {
      return [a.wordCount, b.wordCount]
    }
    if (field === 'nbChallengeCompleted') {
      return [a.curriculumGroup?.stats?.challengesData
        ?.nbChallengesCompleted, b.curriculumGroup?.stats?.challengesData
        ?.nbChallengesCompleted]
    }
    if (field === 'nbLessonsStarted') {
      return [a.lessonsV2Numbers?.started, b.lessonsV2Numbers?.started]
    }
    if (field === 'nbAnimalsCreated') {
      return [a.nbAnimalsCreated, b.nbAnimalsCreated]
    }
    if (field === 'nbRecords') {
      return [a.bookRecords, b.bookRecords]
    }
    if (field === 'nbGamesPlayed') {
      return [a.gamesStats?.nbGamesPlayed, b.gamesStats?.nbGamesPlayed]
    }
    return [a.nickname || a.username, b.nickname || b.username]
  }

  const sortBy = useCallback((field) => {
    if (sortedBy === field && sortDirection === -1) {
      setSortDirection(1)
      setSortedBy('nickname')
    } else if (sortedBy === field) {
      setSortDirection(-1)
    } else {
      setSortedBy(field)
      setSortDirection(1)
    }
  }, [sortedBy, sortDirection])

  const filteredKids = useMemo(() => {
    return displayedKids
      .filter(
        (kid) =>
          kid.username.toUpperCase().includes(search) ||
          (kid.nickname ? kid.nickname.toUpperCase().includes(search) : false)
      )
      .sort((a, b) => {
        const [aField, bField] = fieldsForSortBy(a, b, sortedBy)
        if (aField < bField) return sortDirection
        if (aField > bField) return -sortDirection
        return 0
      })
  }, [search, displayedKids, sortedBy, sortDirection])

  return (
    <>
      {displayedKids.length > 0 && (
        <SectionTitle>Your Night Zookeepers</SectionTitle>
      )}
      <Wrapper>
        { displayedKids.length > 0 && <ChangeLayout>
          <Select
            options={[
              { label: 'Table Layout', value: LAYOUT.TABLE },
              { label: 'Card Layout', value: LAYOUT.CARD },
            ]}
            onChange={(v) => {
              setLayout(v)
              if (typeof window !== 'undefined' && v) {
                localStorage.setItem('preferred-layout', v)
              }
            }}
            value={layout || LAYOUT.CARD}
          />
        </ChangeLayout> }
        { !loading && currentUser?.userType === 'teacher' && !hasInvitedFollowers && <Link to='/ownerships?tab=MANAGE&generate-letters=true'>
          <div style={{ marginBottom: '13px' }}>
            <Button theme='primary' size='small'>Download Letters</Button>
          </div>
        </Link> }
        {!loading && displayedKids.length > 1 ? (
          <Search>
            <input
              placeholder="Search"
              onChange={(event) => setSearch(event.target.value.toUpperCase())}
            />
          </Search>
        ) : null}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Content>
            {layout === LAYOUT.TABLE && <TableLayout kids={filteredKids} sortBy={sortBy} sortedBy={sortedBy} sortDirection={sortDirection} />}
            {layout === LAYOUT.CARD && (
              <CardLayout kids={filteredKids} onKidSelected={onKidSelected} />
            )}
          </Content>
        )}
      </Wrapper>
    </>
  )
}

KidsSelector.propTypes = {
  onKidSelected: PropTypes.func,
}

KidsSelector.defaultProps = {
  onKidSelected: () => {},
}

export default KidsSelector
