import { navigate } from 'gatsby'
import { Avatar, IconButton } from 'nzk-react-components'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SectionTitle } from '../pages/HomePage'
// @ts-ignore
import { ReactComponent as Writing } from '../UI/Icons/writing.svg'
import UserPreview from '../UI/UserPreview'
import useOwnerships from './useOwnerships'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 20px;
  color: white;
`

const Search = styled.div`
  position: absolute;
  top: -50px;
  right: 0px;
  > input {
    width: 280px;
    height: 36px;
    border-radius: 20px;
    border: none;
    outline: none;
    ::placeholder {
      color: #b4b4b3;
    }
    > :active {
      border: none;
    }
    background-color: transparent;
    font-family: 'Libre Baskerville';
    font-size: 18px;
    color: #000000;
    background-color: #ffffff;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  @media only screen and (max-width: 625px) {
    position: relative;
    top: 0px;
    right: auto;
    > input {
      width: 100%;
    }
  }
`

const Content = styled.div`
  position: relative;
`

const Kids = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 40px;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media only screen and (max-width: 625px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

const KidContainer = styled.div`
  position: relative;
`

const Images = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: center;
  > :not(:first-child) {
    margin-left: -35%;
  }
  margin-bottom: 10px;
`

const WritingsContainer = styled.div`
  padding: 25px;
  border-radius: 20px;
  background-color: #341644;
  box-shadow: inset 0 5px 2px -2px #531d75, 0 10px 2px -2px #0000004d;
  position: relative;
  width: 100%;
`

const ButtonContainter = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const Ownerships = ({ onKidSelected }) => {
  const { zookeepers, fetch, loading } = useOwnerships()

  const [search, setSearch] = useState('')
  useEffect(() => {
    fetch()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {zookeepers.length > 0 && (
        <SectionTitle>Children shared with you</SectionTitle>
      )}
      <Wrapper>
        {!loading && zookeepers.length > 1 ? (
          <Search>
            <input
              placeholder="Search"
              onChange={(event) => setSearch(event.target.value.toUpperCase())}
            />
          </Search>
        ) : null}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Content>
            <Kids>
              {zookeepers.length >= 1 ? (
                <KidContainer>
                  <WritingsContainer>
                    <Images>
                      {zookeepers.slice(0, 5).map((kid) => (
                        <Avatar key={kid._id} user={kid} size="75px" />
                      ))}
                    </Images>
                    <ButtonContainter
                      onClick={() => {
                        navigate('/writings/')
                      }}
                    >
                      <IconButton
                        icon={<Writing />}
                        theme="purple"
                        size="x-small"
                      >
                        All Children's Writing
                      </IconButton>
                    </ButtonContainter>
                  </WritingsContainer>
                </KidContainer>
              ) : null}
              {[...zookeepers]
                .filter(
                  (kid) =>
                    kid.username.toUpperCase().includes(search) ||
                    (kid.nickname
                      ? kid.nickname.toUpperCase().includes(search)
                      : false)
                )
                .sort((a, b) => {
                  const aField = a.nickname || a.username
                  const bField = b.nickname || b.username
                  if (aField < bField) return -1
                  if (aField > bField) return 1
                  return 0
                })
                .map((kid) => (
                  <KidContainer key={kid._id}>
                    <UserPreview {...kid} onClick={() => onKidSelected(kid)} />
                  </KidContainer>
                ))}
            </Kids>
          </Content>
        )}
      </Wrapper>
    </>
  )
}

Ownerships.propTypes = {
  onKidSelected: PropTypes.func,
}

Ownerships.defaultProps = {
  onKidSelected: () => {},
}

export default Ownerships
