import { useApolloClient } from '@apollo/client'
import { Button, useAsync } from 'nzk-react-components'
import React from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import { useCurrentUserState } from 'state/CurrentUserState'
// @ts-ignore
import BooksAndCards from '../../assets/images/books-and-cards.png'
import GET_SHOP_COUPON from './graphql/getShopCoupon.graphql'


const Wrapper = styled.div`
  position: sticky;
  bottom: 0px;
  z-index: 3;
`

const ShopLink = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
  background-color: #3D2A46;
  backdrop-filter: blur(5px);

  color: #ffffff;
  font-family: 'Libre Baskerville';
  font-size: 18px;
  z-index: 10;
  * {
    z-index: 10;
  }

  p {
    width: 100%;
    text-align: center;
    padding: 0px;
    margin: 10px 0px 0px 0px;
  }

  > .flex {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
`
const ShopInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: 170px;
  }
  margin-left: 10px;
  margin-right: 10px;
  > :nth-child(2) {
    position: relative;
    top: 5px;
  }
`

const Coupon = styled.div`
  width: 100%;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #251030;
  border-radius: 6px;
  padding: 10px 20px;
  > * {
    margin: 0px 10px;
  }
  > .title {
    font-family: 'Rammetto One';
  }
  > .info {
  }
  > .code {
    display: flex;
    padding: 4px 20px;
    background-color: #701EA8;
    border: 3px solid #9026C1;
    border-radius: 50px;
  }

  &.clickable {
    cursor: pointer;
  }

  min-width: 290px;
`



export default () => {
  const client = useApolloClient()
  const { currentUser } = useCurrentUserState()

  const { data: shopCoupon } = useAsync({
    asyncFunc: async () => {
      const { data } = await client.query({
        query: GET_SHOP_COUPON
      })
      const coupon = data.me?.trialShopCoupon
      if (!coupon) return null
      const validUntil = coupon.createdAt + 7 * 24 * 60 * 60 * 1000
      if (validUntil < Date.now()) return null
      return { ...coupon, validUntil }
    },
    immediate: true
  })

  if (!shopCoupon) return null
  return <Wrapper>
      { currentUser?.userType === 'parent' && 
        <Coupon>
            <div className='title'>30% Off Coupon</div>
            <div className='info'>Valid until&nbsp;
              <FormattedDate
                value={shopCoupon.validUntil}
                month="long"
                year="numeric"
                day="numeric"
              />
            </div>
            <div className='code'>{shopCoupon.code}</div>
        </Coupon>
      }
      { currentUser?.userType === 'teacher' && 
        <Coupon className='clickable' onClick={() =>
          window.open('https://www.nightzookeeper.com/shop', '_blank')
        }>
            <div className='title'>30% Off Shop Coupon</div>
            <div className='info'>Valid until&nbsp;
              <FormattedDate
                value={shopCoupon.validUntil}
                month="long"
                year="numeric"
                day="numeric"
              />
            </div>
            <div className='code'>{shopCoupon.code}</div>
        </Coupon>
      }
      { currentUser?.userType === 'parent' && <ShopLink>
        <ShopInner>
          <img src={BooksAndCards} alt='Book and Cards' />
          <Button
            backgroundColor="#009EE2"
            size="small"
            onClick={() =>
              window.open('https://www.nightzookeeper.com/shop', '_blank')
            }
          >
            Visit Shop
          </Button>
        </ShopInner>
      <p>Books, resources and much more</p>
    </ShopLink> }
  </Wrapper>
}