import { gql, useMutation, useQuery } from '@apollo/client'
import { useModalState } from 'nzk-react-components'
import React, { useEffect, useMemo, useRef } from 'react'
import { AutoClaimRetentionOfferModal } from './Modal'

interface IProps {
  on: 'login' | 'settings-page'
  delay?: number
}

export const useRetentionOfferModalAutoClaim = (props: IProps) => {
  const modal = useModalState()
  const hasShownRef = useRef(false)

  const query = useQuery(gql`
    query getOffers($claimOn: String) {
      me {
        _id
        retention_offers(filters: { claimOn: $claimOn, claimed: false, canClaim: true }) {
          id
          canClaim
          claimed
          available
          archived
          content {
            title
            subTitle
            willUnlockMessage
            worth
            items {
              imageUrl
              title
              subTitle
              description
            }
          }
        }
      }
    }
  `, {
    variables: {
      claimOn: props.on
    },
    fetchPolicy: 'network-only',
  })

  const [claim] = useMutation(gql`
    mutation claimRetentionOffer($on: String, $id: String) {
      retention_claimOffer(on: $on, id: $id)
    }
  `)

  const offer = useMemo(() => {
    return query.data?.me?.retention_offers?.find(o => o.canClaim)
  }, [query])


  useEffect(() => {
    let t
    if (offer && modal.modals.length === 0 && !hasShownRef.current) {
      const show = () => {
        hasShownRef.current = true
        claim({ variables: { on: props.on, id: offer.id } })
        modal.open(<AutoClaimRetentionOfferModal offer={offer} dismiss={() => {
          modal.close('AutoClaimRetentionOfferModal')
        }} />, 'AutoClaimRetentionOfferModal')
      }
      if (!props.delay) show()
      else {
        t = setTimeout(() => {
          show()
        }, props.delay)
      }
    }
    return () => {
      clearTimeout(t)
    }

  }, [offer, modal.modals])
}
