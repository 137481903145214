import styled from 'styled-components'

export const Wrapper = styled.div`
  color: #fff;
  min-height: 200px;
  padding: 30px;
  max-width: 360px;
  text-align: center;
  max-height: 80vh;
  overflow: auto;

  .title {
    font-family: 'Rammetto One';
    font-weight: normal;
    text-shadow: 0px .1em #afafaf, 0px .2em rgba(0, 0, 0, 0.3);
    font-size: 2em;
    letter-spacing: 1.1px;
  }

  .sub-title, .worth {
    font-family: 'Rammetto One';
    font-weight: normal;
    letter-spacing: 1.2px;
    font-size: 1.2em;
    color: #fbe900;
  }

  .worth {
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    .item {
      background-color: #662483;
      border-radius: 15px;
      padding: 15px;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .item--image {
        margin-top: -40px;
        display: flex;
        justify-content: center;
      }
      .item--title {
        font-family: 'Rammetto One';
        font-size: 1.4em;
        letter-spacing: 1px;
      }
      .item--sub-title {
        font-family: 'Rammetto One';
        letter-spacing: 1px;
      }
      .item--description {

      }
    }
  }
`