import React from 'react'
import styled from 'styled-components'
import RatioBox from '../UI/RatioBox'

interface IProps {
  vimeoId: string,
  ratio?: '16:9' | '4:3' | '3:2' | '8:5',
  options?: {
    color: string,
    title: string,
    byline: string,
    portrait: string
  }
}

const VideoWrapper = styled.div`
  width: 100%;
`

const VimeoEmbed = (props: IProps) => {
  const { vimeoId, options, ratio } = props

  const params = new URLSearchParams(options).toString()

  return <VideoWrapper>
    <RatioBox ratio={ratio || '16:9'}>
      <iframe
        title="Vimeo Embed"
        src={`https://player.vimeo.com/video/${vimeoId}?${params}`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </RatioBox>
  </VideoWrapper>
}

VimeoEmbed.defaultProps = {
  options: {
    color: 'ffffff',
    title: '0',
    byline: '0',
    portrait: '0'
  },
  ratio: '16:9'
}

export default VimeoEmbed
