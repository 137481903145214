import Avatar from 'components/UI/Avatar'
import { Link } from 'gatsby'
import { ArrowLeft, Button, Challenges, Controller, DrawingTask, IconButton, Lessons, Progress, Settings, Torch, Writing } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'

interface IProps {
  kids: any[]
  sortBy: (field: string) => void
  sortedBy: string
  sortDirection: number
}

const Wrapper = styled.div`
`

const Content = styled.div`
  position: relative;
  max-height: calc(100vh - 300px);
  min-height: 450px;
  overflow: auto;
`

const Table = styled.table`
  border-spacing: 0;
  position: relative;
  > thead {
    position: relative;
    font-size: 13px;
    th:first-child {
      border-radius: 10px 0 0 0;
    }
    th:last-child {
      border-radius: 0 10px 0 0;
    }
    
    th:first-child:before, th:last-child:before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      content: "";
      z-index: -1;
      background-color: #1A0425;
    }
    th:first-child:after, th:last-child:after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      content: "";
      z-index: 0;
      background-color: #30173A;
    }
    th:last-child:after {
      border-radius: 0 10px 0 0;
    }
    th:first-child:after{
      border-radius: 10px 0px 0 0;
    }
  }
  
  th {
    position: sticky;
    top: 0;
    background-color: #30173A;
    z-index: 1;
    padding-top: 8px;
    box-shadow: 0 3px 0 rgba(0,0,0,0.2);
    
  }
  tbody {
    :before {
      content: "-";
      display: block;
      line-height: 10px;
      color: transparent;
    }
    tr:first-child {
      td:nth-child(2) {
        border-radius: 10px 0px 0px 0px;
      }
      td:nth-child(7) {
        border-radius: 0px 10px 0px 0px;
      }
    }
    tr:last-child {
      td:nth-child(2) {
        border-radius: 0px 0px 0px 10px;
      }
      td:nth-child(7) {
        border-radius: 0px 0px 10px 0px;
      }
    }

  }
  td {
    text-align: right;
    padding: 10px 0;
  }
  td:first-child {
    text-align: initial !important;
    padding: 10px 10px;
    a {
      text-decoration: none;
      color: inherit;
    }
  }
  td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6), td:nth-child(7) {
    padding: 0px 0px;
  }
  td:nth-child(2) {
    background-color: #60802a;
  }
  td:nth-child(3) {
    background-color: #7d001f;
  }
  td:nth-child(4) {
    background-color: #13274c;
  }
  td:nth-child(5){
    background-color: #d84f23;
  }
  td:nth-child(6) {
    background-color: #9e7b2b;
  }
  td:nth-child(7) {
    background-color: #60802a;
  }
`

const ActionsCell = styled.div`
  display: flex;
  padding: 0 10px;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  a {
    text-decoration: none;
    z-index: 0;
  }
`

const User = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 20px;
  }
  font-size: 13px;
`

const NumberCell = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0 10px;
  height: 100%;
  height: 53px;
  line-height: 53px;
`

const SortInfo = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  top: 5px;
  left: calc(50% - 46px);
`

const SortIcon = ({ col, sortedBy, sortDirection }: { col: string, sortedBy: string, sortDirection: number }) => {
  return sortedBy === col ? <SortInfo>
    { sortDirection === -1 && <div style={{ transform: 'rotate(90deg) translateY(5px)' }}>
      <ArrowLeft />
    </div> }
    { sortDirection === 1 && <div style={{ transform: 'rotate(-90deg)' }}>
      <ArrowLeft />
    </div> }
  </SortInfo> : null
}

const TableLayout = (props: IProps) => {
  return (
    <Wrapper>
      <Content>
        <Table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>
                <div className="column-header">
                  <Button round size="x-small" backgroundColor="#86C941"  onClick={() => props.sortBy('wordCount')}>
                    <Writing />
                  </Button>
                  <SortIcon col='wordCount' sortedBy={props.sortedBy} sortDirection={props.sortDirection} />
                  <div>Words Written</div>
                </div>
              </th>
              <th>
                <div className="column-header">
                  <Button round size="x-small" backgroundColor="#C10020" onClick={() => props.sortBy('nbChallengeCompleted')}>
                    <Challenges />
                  </Button>
                  <SortIcon col='nbChallengeCompleted' sortedBy={props.sortedBy} sortDirection={props.sortDirection} />
                  <div>Challenges Completed</div>
                </div>
              </th>
              <th>
                <div className="column-header">
                  <Button round size="x-small" backgroundColor="#113076" onClick={() => props.sortBy('nbLessonsStarted')}>
                    <Lessons />
                  </Button>
                  <SortIcon col='nbLessonsStarted' sortedBy={props.sortedBy} sortDirection={props.sortDirection} />
                  <div>Lessons Started</div>
                </div>
              </th>
              <th>
                <div className="column-header">
                  <Button round size="x-small" backgroundColor="#FF8133" onClick={() => props.sortBy('nbAnimalsCreated')}>
                    <DrawingTask />
                  </Button>
                  <SortIcon col='nbAnimalsCreated' sortedBy={props.sortedBy} sortDirection={props.sortDirection} />
                  <div>Animals Created</div>
                </div>
              </th>
              <th>
                <div className="column-header">
                  <Button round size="x-small" backgroundColor="#F6C645" onClick={() => props.sortBy('nbRecords')}>
                    <Torch />
                  </Button>
                  <SortIcon col='nbRecords' sortedBy={props.sortedBy} sortDirection={props.sortDirection} />
                  <div>Reading Records</div>
                </div>
              </th>
              <th>
                <div className="column-header">
                  <Button round size="x-small" backgroundColor="#FA0033" onClick={() => props.sortBy('nbGamesPlayed')}>
                    <Controller />
                  </Button>
                  <SortIcon col='nbGamesPlayed' sortedBy={props.sortedBy} sortDirection={props.sortDirection} />
                  <div>Games Played</div>
                </div>
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {props.kids.map((kid) => {
              return <tr>
                <td>
                  <Link to={`/student/${kid.username}?page=content`}>
                    <User>
                      <div>
                        <Avatar user={kid} size="50px" />
                      </div>
                      <div>
                        <div>{kid.nickname}</div>
                        <div>{kid.username}</div>
                      </div>
                    </User>
                  </Link>
                </td>
                <td><NumberCell>{kid.wordCount || 0}</NumberCell></td>
                <td><NumberCell>{kid.curriculumGroup?.stats?.challengesData?.nbChallengesCompleted || 0}</NumberCell></td>
                <td><NumberCell>{kid.lessonsV2Numbers?.started || 0}</NumberCell></td>
                <td><NumberCell>{kid.nbAnimalsCreated || 0}</NumberCell></td>
                <td><NumberCell>{(kid.bookRecords || []).length}</NumberCell></td>
                <td><NumberCell>{kid.gamesStats?.nbGamesPlayed}</NumberCell></td>
                <td>
                  <ActionsCell>
                    <Link to={`/student/${kid.username}?page=settings`}>
                      <Button theme="purple" round size="x-small">
                        <Settings />
                      </Button>
                    </Link>
                    <Link to={`/student/${kid.username}?page=content`}>
                      <Button theme="purple" round size="x-small">
                        <Progress />
                      </Button>
                    </Link>
                  </ActionsCell>
                </td>
              </tr>
            })}
          </tbody>
        </Table>
      </Content>
      <Actions>
        <Link to="/writings">
          <IconButton
            icon={<Writing />}
            theme="purple"
            size="x-small"
          >
            All Children's Writing
          </IconButton>
        </Link>
      </Actions>
    </Wrapper>
  )
}

export default TableLayout
