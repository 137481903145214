import AddNicknameLink from 'components/AddNicknameLink'
import { navigate } from 'gatsby'
import useAmplitude from 'hooks/useAmplitude'
import { Avatar, Button, IconButton, User } from 'nzk-react-components'
import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import ChildLoginPopup from 'components/ChildLoginPopup'
import { AppState } from '../../../state/AppState'
import { CurrentUserState } from '../../../state/CurrentUserState'
// @ts-ignore
import { ReactComponent as Progress } from '../Icons/Progress.svg'
// @ts-ignore
import { ReactComponent as Settings } from '../Icons/Settings.svg'
import { ModalState } from '../../../state/ModalState'

const YEAR_TO_AGE = [
  { value: 0, label: '4-5' },
  { value: 1, label: '5-6' },
  { value: 2, label: '6-7' },
  { value: 3, label: '7-8' },
  { value: 4, label: '8-9' },
  { value: 5, label: '9-10' },
  { value: 6, label: '10-11' },
  { value: 7, label: '12+' },
]

const Child = styled.div`
  border-radius: 20px;
  padding: 25px;
  position: relative;
  ${(props: { expired: boolean }) => props.expired
    ? css`
      background-color: #901a26;
      box-shadow: inset 0 5px 2px -2px #C41825, 0 10px 2px -2px #500008;
    `
    : css`
      background-color: #341644;
      box-shadow: inset 0 5px 2px -2px #531d75, 0 10px 2px -2px #0000004d;
    `}
  font-size: 13px;
  position: relative;
  display: inline-block;
  align-items: center;
  width: 100%;
`

const ChildContent = styled.div`
  min-height: 80px;
  position: relative;
  margin-bottom: 10px;
`

const ChildDetails = styled.div`
  position: absolute;
  top: -10px;
  left: 90px;
  color: #ffffff;
  overflow: hidden;
  line-height: 1em;
  p {
    margin-bottom: 5px;
  }
`

const AccountExpiredLabel = styled.p`
  color: #FFED00;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
`

interface IProps {
  birthYear?: number
  birthMonth?: number
  yearGroup: number
  username: string
  nickname?: string
  avatar: {
    url: string
  }
  onClick?: () => void
  isExpired?: boolean
}

const UserPreview = (props: IProps) => {
  const { managedZookeepers } = AppState.useContainer()
  const [nickname, setNickname] = useState(props.nickname)
  const { currentUser } = CurrentUserState.useContainer()
  const { displayModal, dismissModal } = ModalState.useContainer()
  const { logEvent } = useAmplitude()

  const getAge = () => {
    if (props.birthYear && props.birthMonth) {
      return `${new Date().getUTCFullYear() - props.birthYear - (props.birthMonth <= new Date().getMonth() + 1 ? 0 : 1)} years old`
    }
    if (YEAR_TO_AGE[props.yearGroup]) {
      return `${YEAR_TO_AGE[props.yearGroup].label} years old`
    }
    return 'Not Set'
  }

  const permissions = useMemo(() => {
    const ownership = managedZookeepers[props.username]
    if (!ownership) return null
    return ownership.ownershipPermissions
  }, [managedZookeepers])

  const loginAsChild = () => {
    displayModal(<ChildLoginPopup students={[{
      username: props.username,
      avatar: props.avatar,
      nickname: props.nickname || ''
    }]} onClose={dismissModal} />)
  }

  return (
    <Child onClick={() => !props.isExpired && props.onClick && props.onClick()} expired={props.isExpired || false}>
      <ChildContent>
        <Avatar user={{ type: 'student', ...props }} size="75px" />
        <ChildDetails>
          <p>{nickname || props.username}</p>
          { !props.isExpired && <p>{nickname ? props.username : <AddNicknameLink
          user={props}
          modalDisplay
          onChanged={(value) => setNickname(value)}
        /> }</p> }
          <p>Age: {getAge()}</p>
          { props.isExpired && <AccountExpiredLabel>Account Expired</AccountExpiredLabel> }
        </ChildDetails>
      </ChildContent>
      { props.isExpired && <Buttons>
          { managedZookeepers[props.username]
          ? <Button theme='confirm' size='x-small' onClick={() => {
            logEvent('Dashboard: Reactivate', { from: 'HomePage '})
            navigate('/welcome?page=SelectAPlan')
          }} disabled={managedZookeepers[props.username] && permissions.canTakeOwnerships}>Reactivate</Button>
        : <Button theme='confirm' size='x-small' onClick={() => {
            logEvent('Dashboard: Reactivate', { from: 'HomePage '})
            navigate('/subscribe')
          }} disabled={managedZookeepers[props.username] && permissions.canTakeOwnerships}>Reactivate</Button>
        }
      { currentUser?.customPlan && <Button theme='confirm' size='x-small' onClick={() => {
        navigate('/ownerships?tab=MANAGE')
      }}>Invite Parent</Button> }
        </Buttons>}
      { !props.isExpired && <Buttons>
        <IconButton
          icon={<Progress />}
          theme="purple"
          size="x-small"
          onClick={() => {
            navigate(`/student/${props.username}?page=content`)
          }}
        >
          Progress
        </IconButton>
        <IconButton
          icon={<Settings />}
          theme="purple"
          size="x-small"
          onClick={() => {
            navigate(`/student/${props.username}?page=settings`)
          }}
        >
          Settings
        </IconButton>
        <IconButton onClick={loginAsChild} icon={<User />} theme='primary' size='x-small'>Child Login</IconButton>
      </Buttons> }
    </Child>
  )
}

UserPreview.defaultProps = {
  birthYear: null,
  birthMonth: null,
  nickname: null,
  onClick: () => {},
  isExpired: false
}

export default UserPreview
