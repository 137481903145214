import { graphql, Link, useStaticQuery } from "gatsby"
import { Button } from 'nzk-react-components'
import React, { useEffect, useMemo, useRef, useState } from "react"
import { animated, useSpring } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import styled, { css } from 'styled-components'
import { gql, useQuery } from '@apollo/client'
import { ReactComponent as LeftArrow } from '../../assets/images/icons/icon-left-arrow.svg'
import { ReactComponent as RightArrow } from '../../assets/images/icons/icon-right-arrow.svg'
import { CurrentUserState } from '../../state/CurrentUserState'
import BundleMultipack from '../../assets/images/bundle-multipack.png'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`

const Content = styled(animated.div)`
  display: flex;
  align-items: center;
  overflow-x: hidden;
  width: 100%;
  padding-bottom: 10px;
  > * {
    margin-right: 20px;
  }
  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }
`

const LinkWrapper = styled.div`
  width: 220px;
  background-color: #30173a;
  border-radius: 20px;
  box-shadow: 0px 5px 0px #1c0725, 0px 10px 0px #140217;
  text-decoration: none;
`

const LinkImage = styled.div<{ imageUrl: string }>`
  width: 220px;
  height: 125px;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-size: cover;
  border-radius: 20px;
`

const TwoLines = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
`

const LinkText = styled.div<{ length: number }>`
  position: relative;
  font-size: ${(props: { length: number }) => {
    if (props.length <= 30) {
      return '14px'
    }
    if (props.length > 40) {
      return '10px'
    }
    return '12px'
  }};
  line-height: 20px;
  padding: 10px;
  color: white;
  height: 60px;
`

interface ButtonProps {
  visible: boolean
}

const ButtonWrapper = styled.div`
  transition: opacity 0.5s ease-in-out;

  ${(props: ButtonProps) =>
    props.visible
      ? css`
          opacity: 1;
          pointer-events: all;
          cursor: pointer;
        `
      : css`
          opacity: 0;
          pointer-events: none;
          cursor: initial;
        `}
`

const PreviousButton = styled(ButtonWrapper)`
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 2;
  transform: translateY(-50%);
`

const NextButton = styled(ButtonWrapper)`
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 2;
  transform: translateY(-50%);
`

export interface ILink {
  id?: string
  title: string
  targetUrl?: string
  external: boolean
  onClick?: () => {}
  shownToTeachers: boolean
  shownToParents: boolean
  published_at: Date,
  image: {
    url: string
  }
}
interface IProps {
  prependLinks: ILink[]
}

export default (props: IProps) => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        parentDashboardHelpfulLinks {
          id
          title
          targetUrl
          external
          image {
            url
          }
          shownToTeachers
          shownToParents
          published_at
        }
      }
    }
  `)

  const contentRef = useRef<HTMLDivElement | null>(null)
  const { currentUser } = CurrentUserState.useContainer()
  const [scrollLeft, setScrollLeft] = useState(0)
  const spring = useSpring({ scroll: scrollLeft })
  const [index, setIndex] = useState(0)
  const [canNext, setCanNext] = useState(false)
  const [links, setLinks] = useState(
    currentUser
      ? props.prependLinks.concat(data.strapi.parentDashboardHelpfulLinks).filter(d => {
        if (!d.shownToParents && !d.shownToTeachers) return true
        if (d.shownToParents && d.shownToTeachers) return true
        if (!d.shownToTeachers && ['teacher'].indexOf(currentUser.userType || 'parent') >= 0) return false
        if (!d.shownToParents && (!currentUser.userType || ['parent'].indexOf(currentUser.userType) >= 0)) return false
        return true
      }).sort((a, b) => new Date(b.published_at).getTime() - new Date(a.published_at).getTime())
      : []
  )

  const nbLinksFullyOnScreen = useRef(0)
  const nbLinks = links.length

  const { data: retentionLinksData } = useQuery(gql`
    query getRetentionHelpfulLinks {
      me {
        _id
        retention_offers {
          id
          helpfulLinks {
            title
            thumbnailUrl
            url
            external
          }
        }
      }
    }
  `, {
    fetchPolicy: 'network-only'
  })

  const retentionLinks = useMemo(() => {
    return (retentionLinksData?.me?.retention_offers || []).reduce((acc, offer) => {
      return [...acc, ...(offer.helpfulLinks || []).map(link => ({
        title: link.title,
        image: { url: link.thumbnailUrl },
        targetUrl: link.url,
        external: Boolean(link.external)
      }))]
    }, [])
  }, [retentionLinksData])

  useEffect(() => {
    if (contentRef.current) {
      nbLinksFullyOnScreen.current = Math.floor(
        contentRef.current.offsetWidth / 220
      )
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      const baseLinks = data.strapi.parentDashboardHelpfulLinks.filter(d => {
        if (!d.shownToParents && !d.shownToTeachers) return true
        if (d.shownToParents && d.shownToTeachers) return true
        if (!d.shownToTeachers && ['teacher'].indexOf(currentUser.userType || 'parent') >= 0) return false
        if (!d.shownToParents && (!currentUser.userType || ['parent'].indexOf(currentUser.userType) >= 0)) return false
        return true
      }).sort((a, b) => new Date(b.published_at).getTime() - new Date(a.published_at).getTime())
      const prependLinks = props.prependLinks

      setLinks(([...prependLinks, ...retentionLinks, ...baseLinks]))
    }
  }, [props.prependLinks, retentionLinks])

  const getRemainingToScroll = (scrollLeft: number) => {
    if (contentRef.current) {
      return Math.abs(
        contentRef.current.scrollWidth -
          scrollLeft -
          contentRef.current.offsetWidth
      )
    }
  }

  const elementAt = (i: number) => {
    if (!contentRef.current) return null
    if (i > contentRef.current.children.length - 1) return null
    return contentRef.current.children[i]
  }

  useEffect(() => {
    const element = elementAt(index)
    if (contentRef.current && element) {
      // @ts-ignore
      let scrollLeft = element.offsetLeft
      const remaining = getRemainingToScroll(scrollLeft) || 0
      if (remaining < 220) {
        scrollLeft += remaining
      }
      if (scrollLeft < 220) {
        scrollLeft = 0
      }
      setScrollLeft(scrollLeft)
      if (
        scrollLeft + contentRef.current.offsetWidth >=
        contentRef.current.scrollWidth
      ) {
        setCanNext(false)
      } else {
        setCanNext(true)
      }
    }
  }, [index, links])

  const canPrevious = useMemo(() => index > 0, [index])

  const next = () => {
    if (nbLinksFullyOnScreen.current <= 1) {
      setIndex(Math.min(index + 1, nbLinks - 1))
    } else {
      setIndex(Math.min(index + 2, nbLinks - 1))
    }
  }

  const previous = () => {
    if (nbLinksFullyOnScreen.current <= 1) {
      setIndex(Math.max(index - 1, 0))
    } else {
      setIndex(Math.max(index - 2, 0))
    }
  }

  const bind = useDrag(({ down, movement: [x] }) => {
    if (x < -150 && !down) {
      next()
    }
    if (x > 150 && !down) {
      previous()
    }
  })

  const onClickLink = (link, ev) => {
    if (link.onClick) {
      ev.stopPropagation()
      ev.preventDefault()
      link.onClick()
    }
  }

  return <Wrapper>
    <PreviousButton onClick={previous} visible={canPrevious}>
      <Button theme='purple' size='small' round>
        <LeftArrow />
      </Button>
    </PreviousButton>
    { /* @ts-ignore */ }
    <Content scrollLeft={spring.scroll} ref={contentRef} {...bind()}>
      { currentUser?.subscription?.hasAccessToResources && <a href='https://19679358.fs1.hubspotusercontent-na1.net/hubfs/19679358/Multipack%201st%20Edition%20-%20Writing%20Activities.pdf' target="_blank" rel="noreferrer">
        <LinkWrapper>
          <LinkImage imageUrl={BundleMultipack} />
          <LinkText length={'Resources included with your subscription'.length}>
            <TwoLines>Resources included with your subscription</TwoLines>
          </LinkText>
        </LinkWrapper>
      </a> }
      {
        // @ts-ignore
        links.filter(d => (d && d.image)).map((link, index) => (<div key={index} onClickCapture={(ev) => onClickLink(link, ev) }>
          {
            !link.external
              ? <Link to={link.targetUrl || ''}>
                <LinkWrapper>
                  <LinkImage imageUrl={link.image.url} />
                  <LinkText length={link.title.length}>
                    <TwoLines>{link.title}</TwoLines>
                  </LinkText>
                </LinkWrapper>
            </Link>
              : <a href={link.targetUrl || ''} target="_blank" rel="noreferrer">
                <LinkWrapper>
                  <LinkImage imageUrl={link.image.url} />
                  <LinkText length={link.title.length}>
                    <TwoLines>{link.title}</TwoLines>
                  </LinkText>
                </LinkWrapper>
              </a>
          }
          
        </div>))
      }
    </Content>
    <NextButton onClick={next} visible={canNext}>
      <Button theme='purple' size='small' round>
        <RightArrow />
      </Button>
    </NextButton>
  </Wrapper>
}
