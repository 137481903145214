import { Close, Button } from 'nzk-react-components'
import React, { useEffect } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  background-color: #341644;
  color: #fff;
  border-radius: 19px;
  box-shadow: 0 -3px 0 #531d75, 0 4px 0 #1c042b, 0 8px 0 rgba(0, 0, 0, 0.4);
  > :first-child {
    position: absolute;
    top: -5px;
    right: -5px;
  }
`

export interface IBaseModalProps {
  children?: any,
  dismiss: () => void
}

export default (props: IBaseModalProps) => {
  useEffect(() => {
    if (typeof window === 'undefined') return
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return <Wrapper onClick={(e) => { e.stopPropagation() }}>
    <Button theme='red' round size='small' onClick={props.dismiss}>
      <Close />
    </Button>
    { props.children }
  </Wrapper>
}